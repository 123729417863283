<script>
import { checkMobile } from '@/utils/deviceCheck';

import desktop from '@/components/AppJournalPage/JournalGrid/desktop';
import mobile from '@/components/AppJournalPage/JournalGrid/mobile';

export default {
  functional: true,
  render(createElement, context) {
    return createElement(
      checkMobile() ? mobile : desktop,
      context.data,
      context.children,
    );
  },
};
</script>
