import {
  reactive,
  toRefs,
} from '@nuxtjs/composition-api';

import { checkMobile } from '@/utils/deviceCheck';
import formatDate from '@/utils/formatDate';
import { journalTitle } from '@/utils/constants/journalConstants';

import useHttpClient from '@/compositions/useHttpClient';
import useErrorPage from '@/compositions/useErrorPage';

const useJournal = (type) => {
  const httpClient = useHttpClient();
  const errorPage = useErrorPage();

  const localState = reactive({
    titlePage: journalTitle,
    items: [],
    allItems: [],
    topList: [],
    newsList: [],
    postsList: [],
    selectionsList: [],
    popularsList: [],
    isLoading: true,
    sort: 'default',
    pagination: {
      count: 0,
      currentPage: 1,
      perPage: checkMobile() ? 3 : 13,
      total: 0,
      totalPages: 1,
    },
    crumbs: [
      { title: 'Главная', link: '/' },
      { title: journalTitle, link: '/journal/' },
    ],
  });

  const transformData = (items) => {
    if (!items.length) return [];

    const link = (item) => {
      if (item.attributes.type === 'podborki') return `/podborki/${item.attributes?.code}-${item.id}/`;
      if (type === 'people-and-books' || item.attributes.type === 'people-and-book' || item.type === 'pagePeopleAndBooks') {
        return `/people-and-books/${item.attributes?.code}-${item.id}/`;
      }

      return `/${item.attributes.type}/${item.id}/`;
    };

    const image = (item) => {
      if (type === 'people-and-books' || item.attributes.type === 'people-and-book' || item.type === 'pagePeopleAndBooks') {
        return item.attributes.detailPicture?.src;
      }

      return item.attributes.previewPicture?.src;
    };

    return items.map(item => ({
      id: item.id,
      link: link(item),
      title: item.attributes.name,
      image: image(item) || null,
      description: item.attributes.properties?.listText || null,
      date: formatDate(item.attributes.dateActiveFrom, { day: 'numeric', month: 'long', year: 'numeric' }),
    }));
  };

  /**
   * Получаем список постов
   * @param {Object} [payload={}]
   * @param {string} [payload.type]
   * @param {string} [payload.sort]
   * @param {boolean} [payload.showOnMainOnly]
   * @param {number} [payload.pageNumber=1]
   * @param {number} [payload.pageSize=10]
   * @return {Promise<{pagination: *, list: []|*}>}
   */
  const getJournalContent = async (payload = {}) => httpClient.journal.getJournalContent(payload)
    .then(({ items, pagination }) => {
      const list = transformData(items);
      localState.items = list;
      return {
        list,
        pagination,
      };
    });

  /**
   * Получаем список постов Люди и книги
   * @param {Object} [payload={}]
   * @param {string} [payload.type]
   * @param {string} [payload.sort]
   * @param {boolean} [payload.showOnMainOnly]
   * @param {number} [payload.pageNumber=1]
   * @param {number} [payload.pageSize=10]
   * @return {Promise<{pagination: *, list: []|*}>}
   */
  const getPeopleAndBooks = async (payload = {}) => httpClient.journal.getPeopleAndBooks(payload)
    .then(({ items, pagination }) => {
      const list = transformData(items);
      localState.items = list;
      return {
        list,
        pagination,
      };
    });

  /**
   * Выбирает метод получения данных
   * @param payload
   * @return {Promise<{pagination: *, list: ([]|*)}>}
   */
  const getCategoryList = (payload) => {
    if (type === 'people-and-books' || payload.type === 'people-and-books') return getPeopleAndBooks(payload);
    return getJournalContent(payload);
  };

  /**
   * Получаем список всех постов раздела
   * @return {Promise<void>}
   */
  const getAllItems = async ({
    pageNumber = 1,
    pageSize = 13,
    isInfiniteScroll = false,
    disableLoading = false,
    currentItemId,
  } = {}) => {
    if (!disableLoading) localState.isLoading = true;
    await getCategoryList({ type, pageNumber, pageSize, sort: localState.sort })
      .then(({ list, pagination }) => {
        localState.pagination = pagination.pagination;
        if (isInfiniteScroll) {
          localState.allItems.push(...list);
        } else {
          localState.allItems = currentItemId ? list.filter(item => item.id !== currentItemId) : list;
        }
      }).finally(() => {
        localState.isLoading = false;
      });
  };

  /**
   * Получаем список топовых постов (плитка вверху)
   * @return {Promise<void>}
   */
  const getTopList = async () => {
    await getJournalContent({ showOnMainOnly: true, pageNumber: 1, pageSize: 3 })
      .then(({ list }) => localState.topList = list);
  };

  /**
   * Получаем список новостей
   * @return {Promise<void>}
   */
  const getNewsList = async () => {
    await getJournalContent({ type: 'news', pageNumber: 1 })
      .then(({ list }) => localState.newsList = list);
  };

  /**
   * Получаем список лонгридов (блог)
   * @return {Promise<void>}
   */
  const getPostsList = async () => {
    await getJournalContent({ type: 'blog', pageNumber: 1, pageSize: 3 })
      .then(({ list }) => localState.postsList = list);
  };

  /**
   * Получаем список подборок
   * @return {Promise<void>}
   */
  const getSelectionsList = async () => {
    await getJournalContent({ type: 'podborki', pageNumber: 1, pageSize: 3 })
      .then(({ list }) => localState.selectionsList = list);
  };

  /**
   * Получаем список "Это очень интересно"
   * @return {Promise<void>}
   */
  const getPopularsList = async () => {
    await getJournalContent({ sort: 'popular', pageNumber: 1, pageSize: 3 })
      .then(({ list }) => localState.popularsList = list);
  };

  const infiniteScrollHandler = async (state) => {
    const pageNumber = localState.pagination.currentPage + 1;
    if (pageNumber <= localState.pagination.totalPages) {
      await getAllItems({
        pageNumber: localState.pagination.currentPage + 1,
        pageSize: localState.pagination.perPage,
        isInfiniteScroll: true,
        disableLoading: true,
      })
        .then(() => {
          state.loaded();
        });
    } else {
      state.complete();
    }
  };

  const sortHandler = async (typeSort) => {
    localState.sort = typeSort;
    await getAllItems();
  };

  return {
    ...toRefs(localState),
    errorState: errorPage.errorState,
    infiniteScrollHandler,
    getAllItems,
    sortHandler,
    getJournalContent,
    getPopularsList,
    getTopList,
    getPostsList,
    getNewsList,
    getSelectionsList,
    getCategoryList,
  };
};

export default useJournal;
