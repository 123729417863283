export const journalTitle = 'Журнал «Что читать»';

export const journalSort = Object.freeze([
  {
    id: 'popular',
    text: 'Сначала популярные',
  },
  {
    id: 'default',
    text: 'Сначала новые',
  },
]);
