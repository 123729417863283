import {
  useContext,
  reactive,
  readonly,
} from '@nuxtjs/composition-api';

const defaultError = Object.freeze({
  message: 'Default message',
  code: '',
});

const useErrorPage = () => {
  const { res } = useContext();

  const localState = reactive({
    isNotFound: false,
    isServerError: false,
    error: {
      ...defaultError,
    },
  });

  const setErrorObj = (error) => {
    localState.error = error;
  };

  const setNotFoundError = (errorObj = { ...defaultError }) => {
    if (process.server) {
      res.statusCode = 404;
    }
    setErrorObj(errorObj);
    localState.isNotFound = true;
    localState.isServerError = false;
  };

  const setServerError = (errorObj = { ...defaultError }) => {
    if (process.server) {
      res.statusCode = 500;
    }
    setErrorObj(errorObj);
    localState.isServerError = true;
    localState.isNotFound = false;
  };

  return {
    errorState: readonly(localState),
    setNotFoundError,
    setServerError,
  };
};

export default useErrorPage;
