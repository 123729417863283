<template>
  <app-section
    :is-container="false"
    is-bottom-offset
    is-no-y-padding
  >
    <div class="journal-grid">
      <div
        v-if="title"
        class="journal-grid__title"
      >
        {{ title }}

        <app-link
          v-if="showMoreLink"
          class="journal-grid__show-all"
          :href="showMoreLink"
        >
          Смотреть все
        </app-link>
      </div>
      <div
        :class="{ _homepage: isHomepage }"
        class="journal-grid__list"
      >
        <journal-grid-item
          v-for="item in list"
          :key="item.id"
          :pic-src="item.image"
          :item="item"
          class="journal-grid__item"
        />
      </div>
    </div>
  </app-section>
</template>

<script>
import JournalGridItem from '@/components/AppJournalPage/JournalGrid/JournalGridItem';
import AppSection from '@/components/AppSection/desktop';

export default {
  name: 'JournalGrid',
  components: {
    JournalGridItem,
    AppSection,
  },
  props: {
    list: { type: Array, required: true },
    title: { type: String, default: '' },
    showMoreLink: { type: String, default: '' },
    isHomepage: { type: Boolean, default: false },
  },
};
</script>

<style lang="less">
.journal-grid {
  &__title {
    .h2-desktop;

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: @size-x8;
  }

  &__show-all {
    .p;

    color: @link;
    font-weight: 400;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: @size-x6;
    grid-row-gap: @size-x5;

    &._homepage {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
