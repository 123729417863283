<script>
import JournalItem from '@/components/AppJournalPage/JournalItem';

export default {
  name: 'JournalGridItem',
  functional: true,
  render(createElement, context) {
    return createElement(
      JournalItem,
      {
        ...context.data,
        class: ['_longread'],
      },
      context.children,
    );
  },
};
</script>

<style lang="less">
.journal-grid__item {
  &._longread {
    width: auto;
    height: 320px;

    &__title {
      .h3-desktop;
    }
  }
}
</style>
