<template>
  <app-section
    :is-container="false"
    :title="title"
  >
    <div class="journal-grid-mobile">
      <div class="journal-grid-mobile__items">
        <journal-mobile-item
          v-for="item in list"
          :key="item.id"
          :pic-src="item.image"
          :item="item"
          class="journal-grid-mobile__item"
        />
        <app-link
          v-if="showMoreLink"
          class="journal-grid-mobile__show-all"
          :href="showMoreLink"
        >
          Смотреть ещё
        </app-link>
      </div>
    </div>
  </app-section>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import AppSection from '@/components/_mobile/AppSection';
import JournalMobileItem from '@/components/AppJournalPage/JournalItem/mobile';

export default defineComponent({
  name: 'JournalGrid',
  components: {
    AppSection,
    JournalMobileItem,
  },
  props: {
    list: { type: Array, required: true },
    title: { type: String, default: '' },
    showMoreLink: { type: String, default: '' },
  },
});
</script>

<style lang="less">
.journal-grid-mobile {
  &__title {
    .h2-mobile;

    margin-bottom: @size-x4;
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__item {
    margin-bottom: @size-x4;
  }

  &__show-all {
    .p;

    font-weight: 400;
    color: @link;
  }
}
</style>
